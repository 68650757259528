import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const ThumbnailAdditional = ({ project }) => (
  <div className="thumbnail-additional__wrapper">
    <figure className="thumbnail-additional__figure">
      <GatsbyImage
        alt={`${project.clientName}-${project.projectDescription}`}
        image={project.thumbnail.gatsbyImageData}
        imgStyle={{ objectFit: "cover", objectPosition: "center" }}
      />
    </figure>
    <figcaption className="thumbnail-additional__content">
      <h2>
        <span className="dash"></span>
        {project.clientName}
      </h2>
      {!!project.projectDescription &&
        project.projectDescription !== "." &&
        project.projectDescription !== "-" &&
        project.projectDescription !== "—" && (
          <p className="paragraph">{project.projectDescription}</p>
        )}
    </figcaption>
  </div>
);

export default ThumbnailAdditional;
