import React from "react";
import { graphql } from "gatsby";

// Components
import Layout from "../layouts/index";
import Seo from "../components/seo";
import Wrapper from "../components/wrapper";
import WhatHero from "../components/what_we_do/what_hero";
import WhatProjects from "../components/what_we_do/what_projects";

const WhatWeDo = ({ data, path, pageContext }) => {
  return (
    <Layout
      path={path}
      locale={pageContext.locale}
      absolutePath={pageContext.absolutePath}
    >
      <Seo
        slug={data.datoCmsWhatWeDo.slug.link}
        content={data.datoCmsWhatWeDo.seo}
        lang={pageContext.locale}
        publication={data.datoCmsWhatWeDo.meta}
        keywords={data.allDatoCmsProject.edges
          .map((item) => item.node.clientName)
          .join(", ")}
      />
      <React.Fragment>
        <Wrapper color="yellow">
          <WhatHero content={data.datoCmsWhatWeDo} />
        </Wrapper>
        <Wrapper grid>
          <WhatProjects
            content={data.datoCmsWhatWeDo}
            projects={data.allDatoCmsProject.edges}
            additionalProjects={data.allDatoCmsAdditionalProject.edges}
            industries={data.allDatoCmsIndustry.edges}
            skills={data.allDatoCmsSkill.edges}
          />
        </Wrapper>
      </React.Fragment>
    </Layout>
  );
};

export default WhatWeDo;

export const query = graphql`
  query WhatWeDoQuery($locale: String!) {
    datoCmsWhatWeDo(locale: { eq: $locale }) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      seo {
        title
        description
        image {
          url
          height
          width
        }
        twitterCard
      }
      headline
      headlineSeo
      shownProjects
      showMore
      slug {
        link
      }
      filters {
        name
      }
      addProjectsHeadline
      addProjectsSkillHeadline
    }
    allDatoCmsIndustry(
      filter: { locale: { eq: $locale } }
      sort: { fields: position }
    ) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    allDatoCmsSkill(
      filter: { locale: { eq: $locale } }
      sort: { fields: position }
    ) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    allDatoCmsProject(
      sort: { fields: [displayPosition], order: ASC }
      filter: { isJob: { ne: true }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          locale
          headline
          clientName
          industries {
            name
            slug
          }
          skills {
            name
            slug
          }
          thumbnail {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    allDatoCmsAdditionalProject(
      sort: { fields: [displayPosition], order: ASC }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          clientName
          projectDescription
          industries {
            id
            name
            slug
          }
          skills {
            name
            slug
          }
          thumbnail {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
