import React from 'react'

// Components
import Wrapper from '../wrapper'

const WhatHero = ({ content }) => (
  <Wrapper grid>
    <div className="what__hero row no-gutters">
      <h1 dangerouslySetInnerHTML={{__html: content.headline}} />
    </div>
  </Wrapper>
)

export default WhatHero
