import React from "react";
import _flattendeep from "lodash.flattendeep";

// Components
import Thumbnail from "../thumbnail";
import ThumbnailAdditional from "../thumbnail_additional";

// Get available filters from projects
const _getProjectsFromFilterType = (projects, filterType) => {
  let arr = _flattendeep(
    projects.map((project) =>
      project.node[filterType].map((skill) => skill.slug)
    )
  );
  return arr.filter((elem, index, self) => {
    return index === self.indexOf(elem);
  });
};

export default class WhatProjects extends React.Component {
  constructor(props) {
    super(props);

    let _projects = props.projects.filter(
      (item) => item.node.slug !== "default"
    );
    let _additionalProjects = props.additionalProjects.filter(
      (item) => item.node.clientName !== "default"
    );

    this.state = {
      // projects
      projects: _projects,
      filtered: _projects,
      // additional projects
      additionalProjects: _additionalProjects,
      filteredAP: [],
      // filters
      skill: "all",
      industry: "all",
      // project per page
      shownProjects: this.props.content.shownProjects,
    };
  }

  _showMoreCases = () => {
    this.setState((prevState) => ({
      shownProjects: this.state.shownProjects + this.state.shownProjects,
    }));
  };

  _filterProjectsBySkill = (filter) => {
    // Filtering
    if (filter !== "all") {
      // Filter projects
      let filtered_projects = [];
      this.state.projects.forEach((project, index) => {
        project.node.skills.forEach((skill, index) => {
          if (skill.slug === filter) {
            filtered_projects.push(project);
          }
        });
      });

      // Filter additional projects
      let filtered_additionalProjects = [];
      this.state.additionalProjects.forEach((ap, index) => {
        ap.node.skills.forEach((skill, index) => {
          if (skill.slug === filter) {
            filtered_additionalProjects.push(ap);
          }
        });
      });

      this.setState((prevState) => ({
        filtered: filtered_projects ? filtered_projects : this.state.projects,
        filteredAP:
          filtered_additionalProjects.length > 0
            ? filtered_additionalProjects
            : [],
        skill: filter,
        industry: "all",
      }));
    } else {
      this.setState((prevState) => ({
        filtered: this.state.projects,
        filteredAP: [],
        skill: "all",
        industry: "all",
      }));
    }
  };

  _filterProjectsByIndustry = (filter) => {
    // Filtering
    if (filter !== "all") {
      // Filter projects
      let filtered_projects = [];
      this.state.projects.forEach((project, index) => {
        project.node.industries.forEach((industry, index) => {
          if (industry.slug === filter) {
            filtered_projects.push(project);
          }
        });
      });
      // Filter additional projects
      let filtered_additionalProjects = [];
      this.state.additionalProjects.forEach((ap, index) => {
        ap.node.industries.forEach((industry, index) => {
          if (industry.slug === filter) {
            filtered_additionalProjects.push(ap);
          }
        });
      });

      this.setState((prevState) => ({
        filtered: filtered_projects ? filtered_projects : this.state.projects,
        filteredAP:
          filtered_additionalProjects.length > 0
            ? filtered_additionalProjects
            : [],
        industry: filter,
        skill: "all",
      }));
    } else {
      this.setState((prevState) => ({
        filtered: this.state.projects,
        filteredAP: [],
        industry: "all",
        skill: "all",
      }));
    }
  };

  _renderSkills = () => {
    const availableSkills = _getProjectsFromFilterType(
      this.state.projects,
      "skills"
    );

    let r_arr = this.props.skills
      .filter(
        (s) => availableSkills.includes(s.node.slug) || s.node.slug === "all"
      )
      .map((s) => {
        return (
          <li key={`skill-${s.node.id}`}>
            <span
              className={
                this.state.skill === s.node.slug
                  ? "paragraph active"
                  : "paragraph"
              }
              onClick={() => this._filterProjectsBySkill(s.node.slug)}
            >
              {s.node.name}
            </span>
          </li>
        );
      });

    return <ul>{r_arr}</ul>;
  };

  _renderIndustries = () => {
    const availableIndustry = _getProjectsFromFilterType(
      this.state.projects,
      "industries"
    );

    let r_arr = this.props.industries
      .filter(
        (i) => availableIndustry.includes(i.node.slug) || i.node.slug === "all"
      )
      .map((i) => {
        return (
          <li key={`industry-${i.node.id}`}>
            <span
              className={
                this.state.industry === i.node.slug
                  ? "paragraph active"
                  : "paragraph"
              }
              onClick={() => this._filterProjectsByIndustry(i.node.slug)}
            >
              {i.node.name}
            </span>
          </li>
        );
      });

    return <ul>{r_arr}</ul>;
  };

  _renderProjects = () => {
    let r_array = [];
    this.state.filtered.map((item, index) => {
      if (index + 1 <= this.state.shownProjects) {
        let c_item = (
          <div
            className="project
                          col-12
                          col-sm-6
                          col-lg-4"
            data-id={item.node.id}
            key={`project-${index}`}
          >
            <Thumbnail project={item.node} />
          </div>
        );
        r_array.push(c_item);
      }
    });
    return r_array;
  };

  _renderAdditionalProjects = () => {
    let r_array = [];
    this.state.filteredAP.map((item, index) => {
      let c_item = (
        <div
          className="project
                        col-9
                        col-sm-5
                        col-lg-3"
          data-id={item.node.id}
          key={`additional-project-${index}`}
        >
          <ThumbnailAdditional project={item.node} />
        </div>
      );
      r_array.push(c_item);
    });
    return r_array;
  };

  _renderAdditionalProjectsHeadline = () => {
    let headlineBase;
    let slugToReplace;
    let replaceWith;

    if (this.state.skill !== "all" && this.state.industry === "all") {
      headlineBase = this.props.content.addProjectsSkillHeadline;
      slugToReplace = "{skill_slug}";
      replaceWith = this._getSkillName(this.state.skill);
    } else if (this.state.industry !== "all" && this.state.skill === "all") {
      headlineBase = this.props.content.addProjectsHeadline;
      slugToReplace = "{industry_slug}";
      replaceWith = this._getIndustryName(this.state.industry);
    }

    return headlineBase.replace(slugToReplace, replaceWith);
  };

  _getIndustryName = (industrySlug) => {
    const industry = this.props.industries.find(
      (el) => el.node.slug === industrySlug
    );
    return industry.node.name;
  };

  _getSkillName = (skillSlug) => {
    const skill = this.props.skills.find((el) => el.node.slug === skillSlug);
    return skill.node.name;
  };

  render() {
    return (
      <div className="what__projects">
        {/* Filters */}
        <div className="filters__wrapper row">
          <div
            className="filters__industries
                          col-lg-7"
          >
            <h2>{this.props.content.filters[0].name}</h2>
            {this._renderIndustries()}
          </div>
          <div
            className="filters__skills
                          col-lg-4 offset-lg-1"
          >
            <h2>{this.props.content.filters[1].name}</h2>
            {this._renderSkills()}
          </div>
        </div>

        {/* Projects */}
        <div className="projects__wrapper row">{this._renderProjects()}</div>

        {/* Show more */}
        {this.state.filtered.length > this.state.shownProjects && (
          <div
            className="project__showmore
                          row col-12 no-gutters
                          align-self-center
                          justify-content-center"
          >
            <span
              className="paragraph link"
              onClick={() => this._showMoreCases()}
            >
              {this.props.content.showMore}
            </span>
          </div>
        )}

        {/* Additional projects */}
        {this.state.filteredAP.length > 0 && (
          <div className="additional-projects__wrapper">
            <div
              className="additional-projects__title
                            row
                            col-lg-8
                            col-md-12"
            >
              <h5>{this._renderAdditionalProjectsHeadline()}</h5>
            </div>
            <div className="additional-projects__list">
              <div className="row additional-projects__list__wrapper">
                {this._renderAdditionalProjects()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
